<template>
  <div id="logsReportElem" class="common-wrap-elem logs-report-wrap" data-key="LOGS_REPORT_PAGE">
    <div class="logs-report-form-wrap">
      <div class="form-left-wrap">
        <label for="">{{ dateRange.title }}:</label>
        <Button
          v-for="(btnItem, btnIndex) in dateRange.btnList"
          :key="btnIndex"
          :class="[{'active': btnItem.isActive }]"
          @click="commonClickHandle(btnItem)">{{ btnItem.desc }}</Button>
        <div v-if="formModel.time_tab == '3'" class="date-wrap">
          <el-date-picker
            v-model="date_range"
            type="monthrange"
            size="mini"
            align="right"
            unlink-panels
            value-format="yyyy-MM"
            range-separator="~"
            popper-class="logsReport-DateWrap"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            :append-to-body="false"
            :picker-options="pickerOptions"
            @change="dateChangeHandle">
          </el-date-picker>
        </div>
      </div>
      <div class="form-right-wrap">
        <div
          v-for="(selectItem, selectIndex) in selectList"
          class="form-select-cell"
          :data-index="selectIndex"
          :data-key="selectItem.key"
          :key="selectItem.key">
          <label for="">{{ selectItem.title }}:</label>
          <Select
            style="width:120px;margin-left: 4px;"
            :clearable="selectItem.key=='serial_no'"
            :filterable="selectItem.key=='serial_no'"
            v-model="formModel[selectItem.key]"
            :element-id="`selectElem${selectItem.key}`"
            :data-key="selectItem.key"
            @on-change="(value) => commonChangeHandle({value, ...selectItem})">
            <Option v-for="item in selectItem.options" :data-val="item.value" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
        </div>
      </div>
    </div>
    <!-- 分割线 -->
    <div class="split-row"></div>
    <!-- 柱状图 -->
    <div class="bar-chart-wrap">
      <div class="bar-area-cell">
        <div class="data-item-cell customer-cell">
          <div class="item-num-cell" :style="{'width': !isAllStoreArea ? '70%' : ''}">
            <div class="customer-icon"></div>
            <template v-if="isAllStoreArea">
              <div class="same-num-cell">
                <h5 class="title">{{ totalCustomerData.title }}</h5>
                <p class="desc">{{ totalCustomerData.count }}<span>{{ totalCustomerData.unit }}</span></p>
              </div>
            </template>
            <template v-else>
              <div class="same-num-cell">
                <h5 class="current-title store-name" :title="currentStoreData.title">{{ currentStoreData.title }}</h5>
              </div>
            </template>
          </div>
          <div class="item-num-cell" :style="{'width': !isAllStoreArea ? '30%' : ''}">
            <div v-if="!isAllStoreArea" class="same-num-cell customer-sort-cell">
              <p class="desc"><span>{{ currentStoreData.srotDesc }}</span>{{ currentStoreData.sortNum }}</p>
            </div>
          </div>
        </div>
        <!-- 门店数和机器数 -->
        <div class="data-item-cell machine-cell">
          <div class="item-num-cell">
            <div class="store-icon"></div>
            <div class="same-num-cell">
              <h5 class="title">{{ totalStoreData.title }} <span class="num-cell">{{ totalStoreData.count }}</span>{{ totalStoreData.unit }}</h5>
              <h5 class="title">{{ totalStoreData.newStoreTitle }} <span class="num-cell">{{ totalStoreData.newStoreCount }}</span>{{ totalStoreData.unit }}</h5>
              <!-- <p class="desc">{{ totalStoreData.count }}<span>{{ totalStoreData.unit }}</span></p> -->
            </div>
          </div>
          <div class="item-num-cell">
            <div class="machine-icon"></div>
            <div class="same-num-cell">
              <h5 class="title">{{ totalMachineData.title }} <span class="num-cell">{{ totalMachineData.count }}</span>{{ totalMachineData.unit }}</h5>
              <h5 class="title">{{ totalMachineData.newMachineTitle }} <span class="num-cell">{{ totalMachineData.newMachineCount }}</span>{{ totalMachineData.unit }}</h5>
              <!-- <p class="desc">{{ totalMachineData.count }}<span>{{ totalMachineData.unit }}</span></p> -->
            </div>
          </div>
        </div>
      </div>
      <!-- 柱状图 top10客户或者门店 -->
      <div class="bar-area-cell cell-border top10-cell">
        <h4 class="cell-title"><span class="title-icon"></span>{{ tempTopData.title }}</h4>
        <div id="tempTop10Elem" class="chart-top10-cell"></div>
        <div v-if="isNoTempData" class="empty-wrap">
          <div class="empty-img-bg"></div>
          暂无数据
        </div>
        <Spin v-show="isRequesting" fix></Spin>
      </div>
      <!-- 柱状图 top10城市 -->
      <div class="bar-area-cell cell-border top10-cell">
        <h4 class="cell-title"><span class="title-icon"></span>{{ top10Cities.title }}</h4>
        <div id="tempTop10CityElem" class="chart-top10-cell"></div>
        <div v-if="isNoCityData" class="empty-wrap"><div class="empty-img-bg"></div>暂无数据</div>
        <Spin v-show="isRequesting" fix></Spin>
      </div>
    </div>
    <!-- 分割线 -->
    <div class="split-row"></div>
    <div class="map-and-line-wrap">
      <div id="fullScreenWrap" class="bottom-cell map-cell">
        <h4 class="cell-title" :style="{'background-color': mapIsFullscreen ? '#e0e0e0' : ''}">
          <span class="title-icon"></span>{{ customerMapData.title }}
          <Button v-show="isHasMapData" class="fullScreenBtn" shape="circle" :icon="!mapIsFullscreen ? 'ios-expand' : 'ios-contract'" @click="clickSwitchFullscreenHandle"></Button>
        </h4>
        <div id="mapElem" class="map-wrap"></div>
        <Spin v-show="isLoadingMap || isRequesting" fix></Spin>
        <div v-if="!isHasMapData" class="empty-wrap" style="top:100px;"><div class="empty-img-bg"></div>暂无数据</div>
      </div>
      <div class="bottom-cell line-cell">
        <h4 class="cell-title"><span class="title-icon"></span>{{ tempLineData.title }}客户登录情况</h4>
        <div id="lineElem" class="line-wrap"></div>
        <div v-if="!isHasLineBarData" class="empty-wrap"><div class="empty-img-bg"></div>暂无数据</div>
        <Spin v-show="isRequesting" fix></Spin>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts';
// 引入该JS getJson('china')才有返回值
import "echarts/map/js/china";
import { logsFormModule, logsMapModule } from "../customizedDb";
const { dateRangeInfo, selectList,
  top10CustomerOption,
  top10CityOption,
  top10StoreOption,
} = logsFormModule || {};
const { mapOption, lineOption, filterLineOptionFn } = logsMapModule;

export default {
  components: {},
  filters: {},
  props: {},
  data() {
    return {
      pickerOptions: {
        // 历史最多能选24个月
        disabledDate(time) {
          // 禁用大于当前月份和小于当前-24个月
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth();
          const twoYearsAgoYear = currentYear - 2;
          const firstDayOfPreviousMonth = new Date(twoYearsAgoYear, currentMonth, 1);
          firstDayOfPreviousMonth.setHours(0, 0, 0, 0);
          const timestamp = firstDayOfPreviousMonth.getTime();
          let isDisabled = false;
          if (time.getTime() > Date.now()) {
            isDisabled = true;
          }
          if (time.getTime() < timestamp ) {
            isDisabled = true;
          }
          return isDisabled;
        },
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()]);
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 12);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      date_range: [],//历史的时候使用
      dateRange: {},
      selectList: [],
      formModel: {
        time_tab: "1",
        time_limit: "",
        terminal_type: "ALL",
        serial_no: "ALL",
      }, // 查询条件，动态追加key
      top10KeyList: ["top10Stores", "top10Customers", "top10Cities"],//动态变化的bar
      totalCustomerData: {
        title: "总客户数(登录)",
        count: "",
        unit: "个",
      },
      currentStoreData: {
        title: "",
        srotDesc: "门店数排名",
        sortNum: "",
      },
      totalStoreData: {
        title: "总门店数",
        count: "",
        unit: "个",
        newStoreTitle: "新增门店数",
        newStoreCount: "100",
      },
      totalMachineData: {
        title: "总机器数",
        count: "",
        unit: "台",
        newMachineTitle: "新增机器数",
        newMachineCount: "99",
      },
      top10Stores: {
        title: "登录频次TOP10门店",
        chartOption: {}
      },
      top10Customers: {
        title: "登录频次TOP10客户",
        chartOption: {}
      },
      top10Cities: {
        title: "登录频次TOP10城市",
        chartOption: {}
      },
      // 中间柱状图数据：top10客户,top10门店
      tempTopData: {},
      isNoTempData: true,
      isNoCityData: true,
      isHasMapData: false,
      isHasLineBarData: false,
      // 地图
      customerMapData: {
        title: "门店登录分布图",
        mapName: "china",
        geoProvinceData: {},//省份城市坐标
        chartOption: {},
        mapData: [],
      },
      // 折线图,默认本月
      tempLineData: {
        title: "本月",
        chartOption: {}
      },
      isLoadingMap: false,// 全屏切换时使用
      mapIsFullscreen: false,//地图是否全屏
      mapChart: null,// 地图图表类,缩放使用
      mapElem: null,// 地图图表DOM元素,缩放使用
      isRequesting: false,
      isAllStoreArea: true, // formModel.serial_no=ALL||!formModel.serial_no
    }
  },
  computed: {},
  watch: {
    "formModel.serial_no":{
      handler(val, old){
        this.isAllStoreArea = (val == "ALL" || !val) ? true : false;
      }
    },
  },
  created() {
    this.dateRange = { ...dateRangeInfo };
    this.selectList = [...selectList];
    // ["top10Stores", "top10Customers", "top10Cities"]
    this.top10Stores.chartOption = { ...top10StoreOption };
    this.top10Customers.chartOption = { ...top10CustomerOption };
    this.top10Cities.chartOption = { ...top10CityOption };
    this.customerMapData.chartOption = mapOption;
    const tempValue = this.formModel.serial_no;
    this.tempLineData.chartOption = filterLineOptionFn(tempValue);
    this.initAllData();
    this.loadCompanyFn();
  },
  mounted() {
    this.filterLineXlabelFn("1")
    // const { mockMapRes, } = logsMapModule;
    // const { code, data = {}} = mockMapRes || {};
    // if (code == 0) {
    //   const { mapData, geoCoordMap } = data;
    //   this.customerMapData.geoProvinceData = geoCoordMap;
    //   this.customerMapData.mapData = mapData;
    //   this.createMapFn();
    // }
    this.loadDataFn();
    this.$nextTick(()=> {
      this.nativeEventFn({ eventType: 'add' });
    })
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.nativeEventFn({ eventType: 'remove' });
  },
  methods: {
    // 清空恢复初始化的值,每次重新查询要使用
    initAllData() {
      this.totalCustomerData.count = 0;
      this.currentStoreData.sortNum = "";
      this.totalStoreData.count = 0;
      this.totalStoreData.newStoreCount = 0;
      this.totalMachineData.count = 0;
      this.totalMachineData.newMachineCount = 0;
      this.tempTopData = {};
      if (this.formModel.time_tab == '3' && (!this.date_range || !this.date_range.length)) {
        this.formModel.time_limit = "";
      }
      // 客户范围匹配中间的柱状图数据
      this.filterTempTop10();
      // 清空地图数据
      this.customerMapData.chartOption.series[0].data = [];
    },
    // 客户下拉列表
    async loadCompanyFn() {
      const res = await R3.network.post("/api/retail/store/getCompany", {});
      const resData = res.data || {};
      const { code, data = [] } = resData;
      if (code == 0) {
        if (data && data.length) {
          data.forEach(item => {
            item["label"] = item.company_name;
            item["value"] = item.serial_no;
          })
          this.selectList.forEach(selectObj => {
            if (selectObj.key == "serial_no") {
              selectObj.options = [...selectObj.options, ...data]
            }
          })
        }
      }
    },
    async loadDataFn() {
      if (this.isRequesting) return;
      if (!this.formModel.time_limit) {
        this.$Message.warning('时间范围不能为空!');
        return;
      }
      if (!this.formModel.serial_no) {
        this.$Message.warning('客户范围不能为空!');
        return;
      }
      const reqOption = { ...this.formModel }
      if (this.formModel.serial_no == 'ALL') {
        reqOption["serial_no"] = "";
      }
      this.isRequesting = true;
      const res = await R3.network.post("/api/retail/store/loginDataStatistics", reqOption);
      // mock-s
      // const { generateDataMockFn } = logsMapModule;
      // const { chartOption } = this.tempLineData || {};
      // const xList = chartOption.xAxis[0].data || [];
      // const resData = generateDataMockFn({ "xAxisLabelList": xList });
      // mock-e
      // console.log(JSON.stringify(resData), 'loadDataFn_001')
      const resData = res.data || {};
      const { code, data } = resData;
      if (code == 0) {
        const {
          total_number_customers:totalNumCustomers,
          total_number_machines:totalNumMachines,
          total_number_stores:totalNumStores,
          total_new_stores:totalNewNum,// 新增门店数
          total_new_machines:totalNewMachineNum, // 新增机器数
          top_ten_customers:topTenCustomers = [],
          top_ten_customers_num:topTenCustomersNum = [],
          top_ten_store:topTenStores = [],
          top_ten_store_num:topTenStoresNum = [],
          top_ten_city:topTenCities = [],
          top_ten_city_num:topTenCitiesNum = [],
          customer_store_rank:rankNum = "",
          customer_installation_situation:lineBarData = {},
          customer_distribution:mapDataList = [],
          history_time_list:historyXLabelList = [],
        } = data;
        this.totalCustomerData.count = totalNumCustomers || "0";
        this.totalStoreData.count = totalNumStores || "0";
        this.totalStoreData.newStoreCount = totalNewNum || "0";
        this.totalMachineData.count = totalNumMachines || "0";
        this.totalMachineData.newMachineCount = totalNewMachineNum || "0";
        this.currentStoreData.sortNum = rankNum || "0";
        // 柱状图:防止后台返回多，截取10条
        this.top10Stores.chartOption.xAxis.data = this.slice10Fn(topTenStores);
        this.top10Stores.chartOption.series[0].data = this.slice10Fn(topTenStoresNum);
        this.top10Customers.chartOption.xAxis.data = this.slice10Fn(topTenCustomers);
        this.top10Customers.chartOption.series[0].data = this.slice10Fn(topTenCustomersNum);
        this.top10Cities.chartOption.xAxis.data = this.slice10Fn(topTenCities);
        this.top10Cities.chartOption.series[0].data = this.slice10Fn(topTenCitiesNum);
        this.filterTempTop10();
        // 地图
        // const mockMapList = [
        //   {
        //     "city_name":"广州",
        //     "latitude_longitude":"113.25,23.1167",
        //     "total_customer_count":"1000"
        //   },
        //   {
        //     "city_name":"上海",
        //     "latitude_longitude":"121.4581,31.2222",
        //     "total_customer_count":"9"
        //   },
        //   {
        //     "city_name":"南京",
        //     "latitude_longitude":"120.585,31.299",
        //     "total_customer_count":"1"
        //   }
        // ]
        if (mapDataList && mapDataList.length) {
        // if (mockMapList && mockMapList.length) {
          this.isHasMapData = true;
          const { mapList, geoInfo, valueList } = this.mapSetData(mapDataList);
          this.customerMapData.geoProvinceData = geoInfo;
          this.customerMapData.mapData = mapList;
          this.customerMapData["staticValList"] = valueList;
          if (mapList && mapList.length) {
            this.createMapFn();
          }
        } else {
          this.isHasMapData = false;
          if (this.mapChart && this.mapElem) {
            this.mapChart.clear();
          }
        }
        // 柱状折线图,自定义时间的底部折线图X坐标使用接口返回的
        const { chartOption:barLineOption } = this.tempLineData;
        if (this.formModel.time_tab == "3" && historyXLabelList && historyXLabelList.length) {
          barLineOption.xAxis[0].data = historyXLabelList;
          this.tempLineData.chartOption = { ...barLineOption };
        }
        const { series = [] } = barLineOption;
        if (lineBarData && Object.keys(lineBarData).length) {
          series.forEach(item => {
            const { key } = item;
            item.data = lineBarData[key] || [];
          })
        } else {
          series.forEach(item => {
            item.data = [];
          })
        }
        // 底部折线图有任意一条有数据就显示
        let anyList = [];
        for (let i = 0; i < series.length; i++) {
          const tempList = series[i].data || [];
          if (tempList && tempList.length) {
            anyList = tempList;
            break;
          }
        }
        if (anyList && anyList.length) {
          this.isHasLineBarData = true;
          this.createLineFn();
        } else {
          this.isHasLineBarData = false;
        }
        this.createTopBar();
        this.isRequesting = false;
      } else {
        this.isRequesting = false;
      }
    },
    // 顶部柱状图X轴标签超过4个字替换为...
    slice10Fn(tempList = []) {
      let replaceList = tempList || [];
      if (tempList && tempList.length > 10) {
        replaceList = tempList.slice(0, 10);
      }
      return replaceList;
    },
    // 设置map数据参数
    mapSetData(list = []) {
      const mapList = [];
      const geoInfo = {};
      const valueList = [];
      if (list && list.length) {
        for (let i = 0; i < list.length; i++) {
          const item = list[i] || {};
          const {
            latitude_longitude:position,
            city_name:cityName,
            total_customer_count:tempVal
          } = item;
          const positionArr = position && position.includes(",") ? position.split(",") : [];
          let startP = positionArr[0] ? Number(positionArr[0]) : "";
          let endP = positionArr[1] ? Number(positionArr[1]) : "";
          // geoInfo[cityName] = [121.48, 31.22];
          geoInfo[cityName] = [startP, endP];
          mapList.push({
            name: cityName || "",
            value: tempVal ? Number(tempVal) : "",
          });
          valueList.push(Number(tempVal) || 0);
        }
      }
      return { mapList, geoInfo, valueList };
    },
    // 当前月份的天数，折线图的底部列数
    getCurrentMonthXLabelFn() {
      const now = new Date();
      const nowYear = now.getFullYear();
      const curMonth = now.getMonth() + 1;
      // 当前月的最大天数
      // const dateTime = new Date(nowYear, curMonth, 0);
      // 当前所在日
      const daysNum = now.getDate();
      const tempMonth = curMonth < 10 ? `0${curMonth}` : curMonth;
      const monthXlabels = [];
      if (daysNum > 0) {
        for (let i = 0; i < daysNum; i++) {
          let tempStr = i + 1;
          tempStr = tempStr < 10 ? `0${tempStr}` : tempStr;
          monthXlabels.push(`${tempMonth}-${tempStr}`);
        }
      }
      const startDate = `${nowYear}-${monthXlabels[0]}`;
      const endDate = `${nowYear}-${monthXlabels[monthXlabels.length - 1]}`;
      this.formModel.time_limit = `${startDate}~${endDate}`;
      return monthXlabels;
    },
    // 当年折线图X轴标签列表
    getCurrentYearXLabelFn() {
      const yearLabels = [];
      const now = new Date();
      const nowYear = now.getFullYear();
      const curMonth = now.getMonth() + 1;
      for (let i = 0; i < curMonth; i++) {
        let tempStr = i + 1;
        tempStr = tempStr < 10 ? `0${tempStr}` : tempStr;
        yearLabels.push(`${tempStr}`);
      }
      const startDate = `${nowYear}-${yearLabels[0]}`;
      const endDate = `${nowYear}-${yearLabels[yearLabels.length - 1]}`;
      this.formModel.time_limit = `${startDate}~${endDate}`;
      return yearLabels;
    },
    // 动态筛选底部柱状折线图的X轴标签列表
    filterLineXlabelFn(btnKey = "") {
      const { chartOption:prevLineOption } = this.tempLineData;
      let tempXLabelData = [];
      if (btnKey == "1") {
        tempXLabelData = this.getCurrentMonthXLabelFn();
      } else if (btnKey == "2") {
        tempXLabelData = this.getCurrentYearXLabelFn();
      }
      // console.log(btnKey, 'btnKey_001')
      prevLineOption.xAxis[0].data = tempXLabelData;
      this.tempLineData.chartOption = { ...prevLineOption };
    },
    commonChangeHandle(curOption = {}) {
      const { value, options = [], key } = curOption;
      if (key == "serial_no" && !value) return;
      this.initAllData();
      if (key == "serial_no") {
        const filterItem = options.filter(item => item.value == value)[0] || {};
        const { label:curTitle } = filterItem;
        this.currentStoreData.title = curTitle;
        // 每次都重新设置下配置选项
        const tempValue = this.formModel.serial_no;
        this.tempLineData.chartOption = filterLineOptionFn(tempValue);
      }
      console.log(this.formModel, curOption, 'formModel_003')
      this.loadDataFn();
    },
    dateChangeHandle(val = []) {
      if (this.formModel.time_tab == '3' && val && val.length == 2) {
        const startMonth = val[0];
        const endMonth = val[1];
        this.initAllData();
        this.formModel.time_limit = `${startMonth}~${endMonth}`;
        this.loadDataFn();
        // console.log(val, this.date_range, 'date_change_001')
      }
    },
    // 按钮点击
    commonClickHandle(btnInfo = {}) {
      const { formKey, valueKey, isActive, desc = "" } = btnInfo;
      if (!isActive) {
        this.initAllData();
        this.setBtnActive(valueKey);
        this.formModel[formKey] = valueKey;
        this.tempLineData.title = desc;
        this.filterLineXlabelFn(valueKey);
        if (valueKey != "3") {
          this.loadDataFn();
        }
      }
      console.log(this.formModel, btnInfo, "this.formModel_001")
    },
    // 按钮点击选中
    setBtnActive(tempKey = "") {
      const list = this.dateRange.btnList || [];
      list.forEach(btnItem => {
        if (btnItem.valueKey == tempKey) {
          btnItem.isActive = true;
        } else {
          btnItem.isActive = false;
        }
      });
    },
    // 根据客户范围选项的值匹配中间的
    filterTempTop10() {
      const temoModel = { ...this.formModel };
      const { serial_no } = temoModel;
      if (serial_no == 'ALL' || !serial_no) {
        this.tempTopData = { ...this.top10Customers }
      } else {
        this.tempTopData = { ...this.top10Stores };
      }
    },
    // 创建top10柱状图
    createTopBar() {
      try {
        const tempTop10Dom = document.getElementById("tempTop10Elem");
        if (tempTop10Dom) {
          this.temp10Chart = echarts.init(tempTop10Dom);
          const { chartOption:tempTopOption } = this.tempTopData;
          const { series = [] } = tempTopOption || {};
          const dataList = series[0].data || [];
          this.isNoTempData = dataList && dataList.length ? false : true;
          if (tempTopOption && Object.keys(tempTopOption)) {
            this.temp10Chart.setOption(tempTopOption);
          }
        }
        // top10城市
        const top10CityDom = document.getElementById("tempTop10CityElem");
        if (top10CityDom) {
          this.top10CityChart = echarts.init(top10CityDom);
          const { chartOption: cityBarOption } = this.top10Cities;
          const { series = [] } = cityBarOption || {};
          const dataList = series[0].data || [];
          this.isNoCityData = dataList && dataList.length ? false : true;
          // console.log(cityBarOption, 'cityBarOption_01');
          if (cityBarOption && Object.keys(cityBarOption)) {
            this.top10CityChart.setOption(cityBarOption);
          }
        }
      } catch (error) {
        console.log('create_chart_bar_error', error);
      }
    },
    createMapFn(initParam = {}) {
      try {
        // const mapFeatures = echarts.getMap("china").geoJson.features;
        // const geoCoordMap = {};
        // mapFeatures.forEach((v)=> {
        //   const name = v.properties.name;
        //   // 省份经纬度
        //   geoCoordMap[name] = v.properties.cp;
        // })
        // console.log(geoCoordMap, 'geoCoordMap_01')
        const { geoProvinceData, chartOption:tempMapOption, mapData = [], staticValList } = this.customerMapData;
        if (!this.mapElem) {
          this.mapElem = document.getElementById("mapElem");
        }
        this.mapChart = echarts.init(this.mapElem);
        if (tempMapOption && Object.keys(tempMapOption)) {
          if (mapData && mapData.length) {
            tempMapOption.series[0].data = this.convertData({
              data: mapData,
              geoCoordMap:geoProvinceData,
              staticValList,
            });
          }
          // console.log(mapData, geoProvinceData, 'map_option_001')
          this.mapChart.setOption(tempMapOption);
        }
      } catch (error) {
        console.log('create_map_error', error)
      }
    },
    // 地图组合数据使用
    convertData ({ data, geoCoordMap, staticValList = [] }) {
      // console.log(staticValList, 'staticValList')
      const res = [];
      // debugger;
      const maxVal = Math.max.apply(null, staticValList) || 0;
      staticValList.sort((a, b) => b - a);
      // 计算前 30% 的位置
      const n = staticValList.length;
      const position = n * 0.30;
      // 获取前 30% 的数据
      const top30PercentData = staticValList.slice(0, Math.floor(position));
      // 输出最后一个数值
      const lastValue = top30PercentData[top30PercentData.length - 1];
      for (let i = 0; i < data.length; i++) {
        const geoCoord = geoCoordMap[data[i].name] || [];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value),
            MAX_VAL: maxVal,// 远点颜色判断使用
            TOP_THIRTY:lastValue
          });
        }
      }
      console.log(maxVal, 'map_data_max_001')
      return res;
    },
    // 底部柱状折线图
    createLineFn() {
      try {
        if (this.tempLineChart) {
          this.tempLineChart.clear();
        }
        const lineChartElem = document.getElementById("lineElem");
        if (lineChartElem) {
          this.tempLineChart = echarts.init(lineChartElem);
          const { chartOption:barLineOption } = this.tempLineData;
          if (barLineOption && Object.keys(barLineOption)) {
            // console.log(barLineOption, 'barLineOption_003')
            this.tempLineChart.setOption(barLineOption);
          }
        }
      } catch (error) {
        console.log('create_chart_bar_error', error);
      }
    },
    // 按钮点击地图图表放大缩小
    clickSwitchFullscreenHandle() {
      try {
        if (this.isLoadingMap) return;
        if (this.mapChart && this.mapElem) {
          if (this.mapIsFullscreen) {
            this.closeFullscreen();
          } else {
            this.openFullscreen();
          }
        }
      } catch (error) {
        this.isLoadingMap = false;
        console.log('switch_fullscreen_error', error);
      }
    },
    /**
     * @param {*} event 
     * 原生监听是否全屏切换，重绘地图，因为全屏后拿不到浏览器自带的 ESC 退出全屏
     */
    nativeScreenChangeFn(event) {
      event.stopPropagation();
      event.preventDefault();
      try {
        if (this.mapChart && this.mapElem) {
          this.isLoadingMap = true;
          this.mapChart.clear();//先清空重绘,防止放大后拖拽导致的不居中
          const fullScreenElem = document.fullscreenElement || document.msFullscreenElement  || document.mozFullScreenElement || document.webkitFullscreenElement || false;
          this.mapIsFullscreen = fullScreenElem ? true : false;
          // 需要延迟处理，不然拿不到最新的宽高
          this.timer = setTimeout(()=> {
            const wrapPosition = this.getDomPositionById('fullScreenWrap');
            const { width, height } = wrapPosition;
            const newWidth = width;
            const windowHeight = window.screen.height;
            const newHeight = fullScreenElem ? (windowHeight - 54) : 480;
            this.mapElem.style.width = `${newWidth}px`;;
            this.mapElem.style.height = `${newHeight}px`;
            // console.log(`newWidth:${newWidth}_02`);
            // console.log(`newHeight:${newHeight}---height:${height}_01`);
            // 放大之后开启缩放配置
            this.customerMapData.chartOption.geo.roam = this.mapIsFullscreen;
            // 得重新绘制不然缩放配置无效
            this.createMapFn();
            // 绘制之后得调用下缩放事件
            this.mapChart.resize();
            this.isLoadingMap = false;
            // 延时1秒放大的时候看不到底部黑屏
          }, 1000)
        }
      } catch (error) {
        console.log('recreate_map_error', error);
      }
    },
    // 提取全屏
    openFullscreen() {
      const fullScreenElem = document.getElementById('fullScreenWrap');
      try {
        if (fullScreenElem.requestFullscreen) {
          fullScreenElem.requestFullscreen();
        } else if (fullScreenElem.webkitRequestFullscreen) {
          /* Safari */
          fullScreenElem.webkitRequestFullscreen();
        } else if (fullScreenElem.msRequestFullscreen) {
          /* IE11 */
          fullScreenElem.msRequestFullscreen();
        }
      } catch (error) {
        console.log(`open_fullscreen_error`, error);
      }
    },
    // 关闭全屏
    closeFullscreen() {
      try {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE11 */
          document.msExitFullscreen();
        }
      } catch (error) {
        console.log('close_fullscreen_error', error);
      }
    },
    // 获取元素坐标
    getDomPositionById(elemId = "") {
      let elemPosition = {};
      if (elemId) {
        const tempElem = document.getElementById(elemId);
        if (tempElem) {
          elemPosition = tempElem.getBoundingClientRect();
        }
      }
      return elemPosition;
    },
    nativeBlurFn(e) {
      if (!this.formModel.serial_no) {
        this.$Message.warning('客户范围不能为空!');
        return;
      }
    },
    // 缩放的时候需要重新绘制一遍所有
    onResizeFn() {
      clearTimeout(this.timer);
      this.timer = setTimeout(()=> {
        if (this.temp10Chart) {
          this.temp10Chart.resize()
        }
        if (this.top10CityChart) {
          this.top10CityChart.resize()
        }
        if (this.mapChart) {
          this.mapChart.resize();
        }
        if (this.tempLineChart) {
          this.tempLineChart.resize();
        }
      }, 300)
    },
    // 注册还是销毁原生事件
    nativeEventFn(eventInfo = {}) {
      const { eventType } = eventInfo;
      const nativeEvents = {
        'add': 'addEventListener',
        'remove': 'removeEventListener',
      }
      const curEvent = nativeEvents[eventType];
      if (curEvent) {
        document[curEvent]('fullscreenchange', this.nativeScreenChangeFn, true);
        const selectInputElem = document.getElementById("selectElemserial_no");
        if (selectInputElem) {
          selectInputElem[curEvent]('blur', this.nativeBlurFn, true);
        }
        window[curEvent]("resize", this.onResizeFn, true);
      }
    },
  }
}
</script>

<style lang="less" scoped>
</style>
