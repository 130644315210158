<template>
  <div class="roc-company-point-wrap">
    <div class="roc-content">
      <template v-if="allBtns && allBtns.length">
        <template v-for="(btnItem, index) in allBtns">
          <span
            type="text"
            class="operate-btn"
            :key="index"
            size="small"
            @click="switchStatusHandle(btnItem)">{{ btnItem.text }}</span>
        </template>
      </template>
      <span v-else :data-row="JSON.stringify(rowData)" />
    </div>
    <Modal
      v-model="isShow"
      title="提示"
      :mask="true"
      :mask-closable="false"
      :footer-hide="true">
      <div class="dialog-content">
        <p v-if="popType !='subnew'" class="msg">{{ msgTip }}</p>
        <div v-else class="date-wrap">
          <p>
            <span>当前截止日期：</span>
            <span>{{ prevExprDate }}</span>
          </p>
          <p>
            <span>续订后截止日期：</span>
            <DatePicker
              type="date"
              placeholder="请选择"
              style="width: 200px"
              format="yyyy-MM-dd"
              v-model="paramDate"
              :options="{ disabledDate: (date) => date && date.valueOf() < (new Date(prevExprDate).getTime()) }"
              @on-change="dateChangeHandle" />
          </p>
        </div>
        <div class="footer-wrap">
          <Button size="large" @click="cancelHandle">取消</Button>
          <Button
            type="primary"
            size="large"
            :disabled="requesting"
            @click="confirmHandle">确定</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  components: {},
  filters: {},
  props: {
    // 这里必须叫params，用来接收表格传给我们的数据
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // disabledFront: {
      //   disabledDate (date) {
      //     // return date && date.valueOf() < (Date.now() - 86400000);
      //     return date && date.valueOf() < (new Date(this.prevExprDate) - 86400000);
      //   }
      // },
      btnMapInfo: {
        // 标准版
        '1': {
          // 使用中
          '2': [{ text: '停用', type: 'disenable' }],
          // 已停用
          '3': [{ text: '启用', type: 'enable' }],
        },
        // 订阅版
        '2': {
          // 使用中
          '2': [{ text: '续订', type: 'subnew' }, { text: '停用', type: 'disenable' }],
          // 已停用
          '3': [{ text: '启用', type: 'enable' }],
        },
        // 软硬一体，和订阅一样
        '3': {
          // 使用中
          '2': [{ text: '续订', type: 'subnew' }, { text: '停用', type: 'disenable' }],
          // 已停用
          '3': [{ text: '启用', type: 'enable' }],
        }
      },
      // taskID=62594
      isShow: false,
      // 所有按钮的状态
      allBtns: [], // 当前行按钮
      rowData: {}, // 当前行所有数据
      msgTip: null, // 启用停用提示
      popType: null, // 按钮类型
      paramId: '', // 接口参数ID
      paramDate: '', // 接口参数日期
      prevExprDate: '', // 显示上次的日期
      requesting: false
    }
  },
  computed: {},
  watch: {},
  created() {
    const { data = {}} = this.params;
    this.rowData = data;
    this.filterCurrentBtns();
  },
  mounted() {},
  destroyed() {},
  methods: {
    // 公用弹框确定逻辑
    confirmHandle() {
      // 停用或者启用
      const param = {
        ID: this.paramId
      };
      const requestUrls = {
        'subnew': '/p/cs/roc/compamyPoint/renewalPoints',
        'disenable': '/p/cs/roc/compamyPoint/enablePoints',
        'enable': '/p/cs/roc/compamyPoint/enablePoints'
      }
      const curUrl = requestUrls[this.popType];
      if (this.popType == 'subnew') {
        // 续订
        if (!this.paramDate) {
          this.$Message.warning('请选择续订后截止日期');
          return;
        }
        param['EXPIRAT_DATE'] = this.paramDate;
      }
      if (!curUrl) return;
      const _self = this;
      this.requesting = true;
      R3.network.post(curUrl, param).then(res => {
        _self.requesting = false;
        _self.isShow = false;
        _self.popType = null;
        _self.msgTip = null;
        _self.paramDate = '';
        const resData = res.data || {};
        const { code, message } = resData;
        if (code == 0) {
          // 成功之后重新查找一下
          try {
            const domElem = document.getElementsByClassName('R3-buttonList')[0];
            if (domElem) {
              const queryBtn = domElem.getElementsByClassName('ark-btn')[0];
              if (queryBtn) {
                queryBtn.click();
              }
            }
          } catch (error) {
            console.log('get-query-btn-error', error);
          }
        } else {
          _self.$Message.error(message);
        }
      }).catch(() => {
        _self.requesting = false;
        _self.isShow = false;
        _self.popType = null;
        _self.msgTip = null;
        _self.paramDate = '';
      });
    },
    cancelHandle() {
      this.isShow = false;
      this.popType = null;
      this.msgTip = null;
      this.paramDate = '';
    },
    dateChangeHandle(val) {
      this.paramDate = val;
      console.log(`续订日期_20221122:${val}`)
    },
    // 按钮操作
    switchStatusHandle(btnInfo) {
      const msgInfo = {
        'enable': '确认启用服务？',
        'disenable': '确认停用服务？',
      }
      const { type } = btnInfo;
      const { ID = {}, EXPIRAT_DATE = {} } = this.rowData;
      const { val: rowId } = ID;
      let { val: expriateDate } = EXPIRAT_DATE;
      const reg = /\//ig;
      expriateDate = (expriateDate && expriateDate.indexOf('/') >= 0) ? expriateDate.replace(reg, '-') : expriateDate;
      this.popType = type;
      this.msgTip = msgInfo[type];
      this.paramId = rowId;
      this.prevExprDate = expriateDate;
      this.isShow = true
    },
    /**
     * 过滤当前行显示的按钮
     * 标准版只有显示一个按钮：服务状态为"使用中"(显示停用),服务状态为"已停用",(显示启用)
     * 订阅版：服务状态为"使用中"，显示"续订"和"停用"，服务状态为"已停用"显示"启用"
     */
    filterCurrentBtns() {
      if (this.rowData && Object.keys(this.rowData).length) {
        // SERVE_TYPE:refobjval  1 标准版本，2 订阅版
        // SERVE_STATUS: refobjval 1：未使用 2：使用中 3.已停用
        const { SERVE_TYPE = {}, SERVE_STATUS = {} } = this.rowData;
        const { refobjval: typeVal } = SERVE_TYPE;
        const { refobjval: statusVal } = SERVE_STATUS;
        const rowBtnObj = this.btnMapInfo[typeVal] || {};
        if (rowBtnObj && Object.keys(rowBtnObj).length) {
          this.allBtns = rowBtnObj[statusVal] || []
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../static/color.less';
.roc-company-point-wrap {
  .roc-content {
    text-align: center;
  }
  .operate-btn {
    display: inline-block;
    color: @info-color;
    cursor: pointer;
    padding: 0 8px;
    &:hover {
      color: @primary-color;
    }
  }
}
.dialog-content {
  padding: 15px 0;
  .msg {
    text-align: center;
    font-size: 16px;
  }
  .date-wrap {
    width: 300px;
    margin: 0 auto;
    line-height: 32px;
  }
  .footer-wrap {
    text-align: right;
    margin-top: 20px;
    .ark-btn {
      padding: 4px 15px 4px 15px;
      margin-right: 10px;
    }
  }
}
</style>
