<template>
  <div class="loginBG" data-key="LOGIN_PAGE">
    <div class="login-wrap">
      <img
        src="../assets/image/logo.1.png"
        alt="logo"
        class="logo-img" />
      <div class="login-content">
        <div class="tab-wrap">
          <span class="tab-btn"
            v-for="(tab, index) in tabBtns"
            :key="index"
            :class="activeType == tab.type ? 'tab-active' : ''"
            @click="switchTypeFn(tab)">{{ tab.txt }}</span>
        </div>
        <div class="login-from" v-if="activeType == 'SMS'">
          <div class="form-row">
            <Icon type="ios-phone-portrait" class="form-icon" />
            <input
              ref="userPhone"
              v-model="phoneNum"
              type="text"
              class="input-cell"
              placeholder="请输入手机号"
              @keyup="nativeKeyUpHandle({ $event })" />
          </div>
          <div class="form-row">
            <!-- <Icon type="ios-calculator-outline" class="form-icon" /> -->
            <!-- <Icon type="ios-chatboxes-outline" class="form-icon" /> -->
            <Icon type="ios-mail-outline" class="form-icon" />
            <input ref="userMsgcode" v-model="smsCode" type="text" class="input-cell" maxlength="6" placeholder="请输入短信验证码" />
            <span v-if="isCanGetMsg" @click="getSmsCode" class="fixed-cell sms-btn">获取短信验证码</span>
            <span v-else class="count-text fixed-cell">{{ second }} 秒后可重发</span>
          </div>
        </div>
        <div class="login-from" v-else>
          <div class="form-row">
            <Icon type="ios-contact-outline" class="form-icon" />
            <input ref="username" type="text" class="input-cell" placeholder="请输入用户名" />
          </div>
          <div class="form-row">
            <Icon type="ios-lock-outline" class="form-icon" />
            <input ref="password" type="password" class="input-cell" placeholder="请输入密码" />
          </div>
        </div>
        <div @click="tempLoginHandle" class="form-btn-wrap">
          <span v-show="!spinShow">登&nbsp;&nbsp;录</span>
        </div>
      </div>
      <Spin
        v-show="spinShow"
        fix>
        <div class="loader">
          <svg
            class="circular"
            viewBox="25 25 50 50"
          >
            <circle
              class="path"
              fill="none"
              stroke-width="5"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
      </Spin>
    </div>
    <!-- <div
      ref="container"
      class="container"
    >
      <img
        src="../assets/image/logo.1.png"
        alt="logo"
        class="logo"
      >
      <span class="titleTOP">用户登录</span>
      <div class="divAccount">
        <img
          src="../assets/image/account.png"
          class="icon"
        >
        <input
          ref="username"
          type="text"
          value=""
          class="username"
          placeholder="请输入用户名"
        >
      </div>
      <div class="divMima">
        <img
          src="../assets/image/password.png"
          class="icon"
        >
        <input
          ref="password"
          type="password"
          value=""
          class="pwd"
          placeholder="请输入密码"
        >
      </div>

      <div
        id="btn"
        class="btn"
        @click="accountLogin"
      >
        <span v-show="!spinShow">登&nbsp;&nbsp;录</span>
      </div>
      <Spin
        v-show="spinShow"
        fix
      >
        <div class="loader">
          <svg
            class="circular"
            viewBox="25 25 50 50"
          >
            <circle
              class="path"
              fill="none"
              stroke-width="5"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
      </Spin>
    </div> -->
  </div>
</template>

<script>

export default {
  name: 'Login',
  data() {
    return {
      path: '../src/assets/images/',
      spinShow: false,
      activeType: "USER",
      tabBtns: [
        {id:"2", type: "USER", txt: "用户登录"},
        {id:"1", type: "SMS", txt: "短信登录"},
      ],
      phoneNum: "",
      smsCode: "",
      timer: null,
      second: 60,
      isCanGetMsg: true,//显示获取验证码按钮
    };
  },
  computed: {
    setLoginImg() {
      return '';
    },
    setLogoImg() {
      return '';
    },
  },
  methods: {
    tempLoginHandle() {
      if (this.activeType == "SMS") {
        this.smsLogin();
      } else {
        this.accountLogin()
      }
    },
    smsLogin() {
      let errorTips = null;
      let focusType = null;
      this.spinShow = true;
      if (!this.smsCode) {
        errorTips = "请输短信验证码";
        focusType = "userMsgcode";
      }
      if (!this.phoneNum) {
        errorTips = "请输入手机号";
        focusType = "userPhone";
      }
      if (errorTips) {
        const message = {
          title: '错误',
          content: errorTips,
          onOk: () => {
            this.spinShow = false;
            if (this.$refs[`${focusType}`]) {
              this.$refs[`${focusType}`].focus();
            }
          }
        };
        this.$Modal.fcError(message);
        return;
      } else {
        const enableGateWay = window.ProjectConfig.enableGateWay;
        const Version = window.ProjectConfig.Version;
        const globalServiceId = window.localStorage.getItem('serviceId');
        this.$network.post(
          enableGateWay ? `/${globalServiceId}/p/c/getCaptcha` : '/p/c/getCaptcha'
        ).then((res) => {
          this.$network.post('/p/c/message/login',
            this.$urlSearchParams({
              phone: this.phoneNum,
              code: this.smsCode,
              captcha: res.data.captcha,
              rememberMe: false,
              lang: 'zh_CN'
            })
          ).then((res) => {
            const resData = res.data || {};
            const { code } = resData;
            if (Version === '1.3') {
              if (res.status === 200 && code === 1) {
                window.location.href = window.location.origin;
              } else {
                this.spinShow = false;
              }
            } else if (res.status === 200 && code === 0) {
              window.location.href = window.location.origin;
            } else {
              this.spinShow = false;
            }
          }).catch((error) => {
            this.spinShow = false;
          });
        });
      }
    },
    // 校验手机号合法性
    regMobileCheck(strVal = "") {
      let endStr = "";
      if (strVal && strVal.length) {
        let firstVal = strVal[0] || "";
        let secondVal = strVal[1] || "";
        let otherVal = strVal.slice(2);
        if (firstVal && firstVal !=1) {
          firstVal = "";
          return "";
        }
        const reg = /[3456789]/;
        if (secondVal && !reg.test(secondVal)) {
          secondVal = "";
          return firstVal;
        }
        endStr = `${firstVal}${secondVal}${otherVal}`;
      }
      return endStr;
    },
    /**
     * 公用的input事件
     * tempInfo { $event, cellItem }
     */
    nativeKeyUpHandle(tempInfo = {}) {
      const { $event } = tempInfo;
      $event.stopPropagation();
      $event.preventDefault();
      const $elem = $event && $event.target;
      let $tempValue = $elem && $elem.value || '';
      // 系统参数控制是否校验手机号
      $tempValue = $tempValue.replace(/[^\d]/g, "");
      if ($tempValue) $tempValue = $tempValue.slice(0, 11);
      if ($tempValue) {
        $tempValue = this.regMobileCheck($tempValue);
      }
      this.phoneNum = $tempValue;
    },
    // 倒计时
    countDown() {
      this.second = this.second - 1;
      if (this.second === 0) {
        clearTimeout(this.timer);
        this.timer = null;
        this.second = 60;
        this.isCanGetMsg = true;
        return;
      }
      this.timer = setTimeout(this.countDown, 1000);
    },
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    // 获取验证码
    getSmsCode() {
      if (!this.isCanGetMsg || this.isRequesting) return;
      let tempMsg = null;
      let tempNum = this.phoneNum || "";
      if (tempNum) {
        tempNum = tempNum.replace(/[^\d]/g, "");
        if (tempNum) tempNum = tempNum.slice(0, 11);
        if (tempNum) {
          tempNum = this.regMobileCheck(tempNum);
        }
        if (tempNum && tempNum.length != 11) {
          tempMsg = "请输入合法的手机号";
        }
      }
      if (!this.phoneNum) {
        tempMsg = "请输入手机号";
      }
      if (tempMsg) {
        const message = {
          title: '错误',
          content: tempMsg,
          onOk: () => {
            if (this.$refs.userPhone) {
              this.$refs.userPhone.focus();
            }
          }
        };
        this.$Modal.fcError(message);
        return;
      }
      this.isRequesting = true;
      this.$network.post('/p/c/getMessageCode',{ phone: this.phoneNum }).then((res) => {
        const resData = res.data || {};
        const { code } = resData;
        this.isRequesting = false;
        if (code == 0) {
          this.isCanGetMsg = false;
          this.countDown();
        }
      }).catch((error) => {
        this.isRequesting = false;
      });
    },
    switchTypeFn(tabInfo = {}) {
      const { type } = tabInfo;
      if (this.activeType != type) this.activeType = type;
    },
    accountLogin() {
      let message = {};
      this.spinShow = true;
      const enableGateWay = window.ProjectConfig.enableGateWay;
      const Version = window.ProjectConfig.Version;

      if (this.$refs.username.value === '') {
        message = {
          title: '错误',
          content: '请输入用户名',
          onOk: () => {
            this.spinShow = false;
          }
        };
        this.$Modal.fcError(message);
      } else if (this.$refs.password.value === '') {
        message = {
          title: '错误',
          content: '请输入密码',
          onOk: () => {
            this.spinShow = false;
          }
        };
        this.$Modal.fcError(message);
      } else if (
        this.$refs.username.value !== ''
        && this.$refs.password.value !== ''
      ) {
        const globalServiceId = window.localStorage.getItem('serviceId');
        this.$network
          .post(
            enableGateWay
              ? `/${globalServiceId}/p/c/getCaptcha`
              : '/p/c/getCaptcha'
          )
          .then((res) => {
            this.$network
              .post(
                enableGateWay ? `/${globalServiceId}/p/c/login` : '/p/c/login',
                this.$urlSearchParams({
                  username: this.$refs.username.value,
                  password: this.$refs.password.value,
                  captcha: res.data.captcha,
                  rememberMe: false,
                  lang: 'zh_CN'
                })
              )
              .then((r) => {
                if (Version === '1.3') {
                  if (r.status === 200 && r.data.code === 1) {
                    window.location.href = window.location.origin;
                  } else {
                    this.spinShow = false;
                  }
                } else if (r.status === 200 && r.data.code === 0) {
                  window.location.href = window.location.origin;
                } else {
                  this.spinShow = false;
                }
              }).catch(() => {
                this.spinShow = false;
              });
          });
      }
    }
  },
  created() {
    document.onkeydown = (e) => {
      const key = e.keyCode;
      if (key === 13) {
        this.tempLoginHandle();
      }
    };
  },
  destroyed() {
    this.clearTimer();
  }
};
</script>

<style lang="less" scoped>
@import '../../static/color.less';
.loginBG {
  background: url(../assets/image/loginBg.jpg) no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  min-width: 660px;
  display: flex;
  justify-content: center;
  /*快鱼logo*/
  .logo {
    position: absolute;
    width: 90%;
    top: -130px;
    left: 15px;
  }
  .login {
    position: absolute;
    top: 33%;
    right: 0;
    width: 610px;
    height: 680px;
  }
  /*整块内容*/
  .container {
    width: 460px;
    height: 267px;
    position: absolute;
    top: 33%;
    right: 12%;
    background-color: #fafafa;
  }
  .iIcon {
    width: 24px;
    height: 24px;
    background-color: #fff;
  }
  /*头部区域*/
  .titleTOP {
    margin: 40px 0 18px 60px;
    font-size: 20px;
    font-weight: bold;
    color: #111419;
    display: inline-block;
    letter-spacing: 2px;
  }

  /*帐号和密码*/
  .username,
  .pwd {
    width: 340px;
    height: 38px;
    border-radius: 4px;
    margin-bottom: 18px;
    box-sizing: border-box;
    padding: 0 20px 0 40px;
    border: 1px solid #dadada;
    background: #f9f9f9;
    color: #575757;
    font-size: 14px;
  }
  .divAccount {
    position: absolute;
    top: 78px;
    left: 60px;
  }
  .icon {
    position: absolute;
    width: 18px;
    height: 18px;
    background-size: cover;
    left: 13px;
    top: 10px;
    color: #575757;
  }
  .divMima {
    position: absolute;
    top: 134px;
    left: 60px;
  }

  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #dadada;
    font-size: 14px;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: 14px;
    color: #dadada;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: 14px;
    color: #dadada;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    font-size: 14px;
    color: #dadada;
  }

  /*滑块*/
  .movebox {
    position: absolute;
    top: 190px;
    left: 60px;
    /*position: relative;*/
    /*margin-left: 60px;*/
    margin-bottom: 32px;
    border-radius: 4px;
    /*background-color: #e8e8e8;*/
    background: #f9f9f9;
    width: 338px;
    /*height: 36px;*/
    line-height: 36px;
    text-align: center;
    border: 1px solid #dadada;
    .txt {
      position: absolute;
      top: 0;
      width: 338px;
      font-size: 14px;
      border-radius: 6px;
      /*background: rgba(255,255,255,.1);*/
      color: #dadada;
      -moz-user-select: none;
      -webkit-user-select: none;
      user-select: none;
      -o-user-select: none;
      -ms-user-select: none;
      height: 36px;
    }
    .movego {
      background-color: #09a155;
      height: 36px;
      width: 0px;
      /*border-radius: 6px;*/
      /*border-radius: 6px;*/
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      /*border: 1px solid #d8d8d8;*/
    }
    .move {
      position: absolute;
      top: -1px;
      left: -1px;
      width: 38px;
      height: 38px;
      /*background: #636363 !important;*/
      /*border: 1px solid #fff;*/
      cursor: pointer;
      border-radius: 4px;
    }
  }
  .line {
    position: absolute;
    top: 260px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #dadada;
  }
  .checkBox {
    display: inline-block;
    position: absolute;
    /*background: red;*/
    top: 288px;
    left: 60px;
    /*margin-left: 60px;*/
  }
  .checkBox > span {
    color: #575757;
    position: absolute;
    width: 80px;
    top: 3px;
    left: 18px;
    font-size: 12px;
  }
  .remenber {
    position: absolute;
    top: 3px;
    width: 14px;
    height: 14px;
  }
  .btn {
    // background: url(../assets/image/login.png) no-repeat;
    background: @primary-color;
    background-size: cover;
    cursor: pointer;
    position: absolute;
    /*display: inline-block;*/
    top: 188px;
    right: 60px;
    width: 340px;
    height: 38px;
    font-size: 16px;
    margin-top: 3px;
    margin-left: 300px;
    color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // ---
  .login-wrap {
    width: 460px;
    height: 270px;
    position: absolute;
    top: 33%;
    right: 12%;
    // left: 10%;
    background-color: #fafafa;
    padding-top: 14px;
    .logo-img {
      position: absolute;
      width: 90%;
      top: -130px;
      left: 15px;
    }
    .login-content {
      width: 80%;
      margin: 0 auto;
    }
    .tab-wrap{
      position: relative;
      display: flex;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      color: #111419;
      .tab-btn {
        border-bottom: 1px solid #dadada;
        padding: 0 15px;
        cursor: pointer;
      }
      .tab-active {
        font-weight: 600;
        border-color: @primary-color;
      }
    }
    
    // form
    .login-from {
      position: relative;
      margin-top: 15px;
    }
    .form-row {
      height: 38px;
      position: relative;
      margin-bottom: 18px;
    }
    .form-icon {
      font-size: 24px;
      position: absolute;
      left: 6px;
      top: 50%;
      transform: translateY(-50%);
    }
    .input-cell{
      display: block;
      width: 100%;
      box-sizing: border-box;
      height: 38px;
      line-height: 38px;
      padding: 0 40px;
      border: 1px solid #dadada;
      background: #f9f9f9;
      color: #575757;
      font-size: 14px;
      border-radius: 4px;
      transition: all 0.3s;
      &:focus {
        border-color: @primary-color;
      }
    }
    .sms-btn {
      color: @primary-color;
      &:hover {
        color: #75849a;
      }
    }
    .fixed-cell {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;
      height: 24px;
      line-height: 24px;
      cursor: pointer;
      font-size: 14px;
      width: 120px;
      text-align: center;
    }
    .count-text {
      color: #8491a5;
    }
    .form-btn-wrap {
      background: @primary-color;
      cursor: pointer;
      width: 100%;
      height: 38px;
      font-size: 16px;
      color: #fff;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
