/**
 * 自定义页面路由配置入口
 */
import LogsReport from "../customizedPage/LogsReport/logsReport.vue"
export default {
  // ROUTER_NAME:{
  //   component: componentName
  // },
  LOGSREPORT: {
    labelName: "客户大屏",
    component: LogsReport
  }  
};
