var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "common-wrap-elem logs-report-wrap",
      attrs: { id: "logsReportElem", "data-key": "LOGS_REPORT_PAGE" },
    },
    [
      _c("div", { staticClass: "logs-report-form-wrap" }, [
        _c(
          "div",
          { staticClass: "form-left-wrap" },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v(_vm._s(_vm.dateRange.title) + ":"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.dateRange.btnList, function (btnItem, btnIndex) {
              return _c(
                "Button",
                {
                  key: btnIndex,
                  class: [{ active: btnItem.isActive }],
                  on: {
                    click: function ($event) {
                      return _vm.commonClickHandle(btnItem)
                    },
                  },
                },
                [_vm._v(_vm._s(btnItem.desc))]
              )
            }),
            _vm._v(" "),
            _vm.formModel.time_tab == "3"
              ? _c(
                  "div",
                  { staticClass: "date-wrap" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "monthrange",
                        size: "mini",
                        align: "right",
                        "unlink-panels": "",
                        "value-format": "yyyy-MM",
                        "range-separator": "~",
                        "popper-class": "logsReport-DateWrap",
                        "start-placeholder": "开始月份",
                        "end-placeholder": "结束月份",
                        "append-to-body": false,
                        "picker-options": _vm.pickerOptions,
                      },
                      on: { change: _vm.dateChangeHandle },
                      model: {
                        value: _vm.date_range,
                        callback: function ($$v) {
                          _vm.date_range = $$v
                        },
                        expression: "date_range",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-right-wrap" },
          _vm._l(_vm.selectList, function (selectItem, selectIndex) {
            return _c(
              "div",
              {
                key: selectItem.key,
                staticClass: "form-select-cell",
                attrs: {
                  "data-index": selectIndex,
                  "data-key": selectItem.key,
                },
              },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(selectItem.title) + ":"),
                ]),
                _vm._v(" "),
                _c(
                  "Select",
                  {
                    staticStyle: { width: "120px", "margin-left": "4px" },
                    attrs: {
                      clearable: selectItem.key == "serial_no",
                      filterable: selectItem.key == "serial_no",
                      "element-id": `selectElem${selectItem.key}`,
                      "data-key": selectItem.key,
                    },
                    on: {
                      "on-change": (value) =>
                        _vm.commonChangeHandle({ value, ...selectItem }),
                    },
                    model: {
                      value: _vm.formModel[selectItem.key],
                      callback: function ($$v) {
                        _vm.$set(_vm.formModel, selectItem.key, $$v)
                      },
                      expression: "formModel[selectItem.key]",
                    },
                  },
                  _vm._l(selectItem.options, function (item) {
                    return _c(
                      "Option",
                      {
                        key: item.value,
                        attrs: { "data-val": item.value, value: item.value },
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "split-row" }),
      _vm._v(" "),
      _c("div", { staticClass: "bar-chart-wrap" }, [
        _c("div", { staticClass: "bar-area-cell" }, [
          _c("div", { staticClass: "data-item-cell customer-cell" }, [
            _c(
              "div",
              {
                staticClass: "item-num-cell",
                style: { width: !_vm.isAllStoreArea ? "70%" : "" },
              },
              [
                _c("div", { staticClass: "customer-icon" }),
                _vm._v(" "),
                _vm.isAllStoreArea
                  ? [
                      _c("div", { staticClass: "same-num-cell" }, [
                        _c("h5", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.totalCustomerData.title)),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "desc" }, [
                          _vm._v(_vm._s(_vm.totalCustomerData.count)),
                          _c("span", [
                            _vm._v(_vm._s(_vm.totalCustomerData.unit)),
                          ]),
                        ]),
                      ]),
                    ]
                  : [
                      _c("div", { staticClass: "same-num-cell" }, [
                        _c(
                          "h5",
                          {
                            staticClass: "current-title store-name",
                            attrs: { title: _vm.currentStoreData.title },
                          },
                          [_vm._v(_vm._s(_vm.currentStoreData.title))]
                        ),
                      ]),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "item-num-cell",
                style: { width: !_vm.isAllStoreArea ? "30%" : "" },
              },
              [
                !_vm.isAllStoreArea
                  ? _c(
                      "div",
                      { staticClass: "same-num-cell customer-sort-cell" },
                      [
                        _c("p", { staticClass: "desc" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.currentStoreData.srotDesc)),
                          ]),
                          _vm._v(_vm._s(_vm.currentStoreData.sortNum)),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "data-item-cell machine-cell" }, [
            _c("div", { staticClass: "item-num-cell" }, [
              _c("div", { staticClass: "store-icon" }),
              _vm._v(" "),
              _c("div", { staticClass: "same-num-cell" }, [
                _c("h5", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.totalStoreData.title) + " "),
                  _c("span", { staticClass: "num-cell" }, [
                    _vm._v(_vm._s(_vm.totalStoreData.count)),
                  ]),
                  _vm._v(_vm._s(_vm.totalStoreData.unit)),
                ]),
                _vm._v(" "),
                _c("h5", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.totalStoreData.newStoreTitle) + " "),
                  _c("span", { staticClass: "num-cell" }, [
                    _vm._v(_vm._s(_vm.totalStoreData.newStoreCount)),
                  ]),
                  _vm._v(_vm._s(_vm.totalStoreData.unit)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-num-cell" }, [
              _c("div", { staticClass: "machine-icon" }),
              _vm._v(" "),
              _c("div", { staticClass: "same-num-cell" }, [
                _c("h5", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.totalMachineData.title) + " "),
                  _c("span", { staticClass: "num-cell" }, [
                    _vm._v(_vm._s(_vm.totalMachineData.count)),
                  ]),
                  _vm._v(_vm._s(_vm.totalMachineData.unit)),
                ]),
                _vm._v(" "),
                _c("h5", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.totalMachineData.newMachineTitle) + " "),
                  _c("span", { staticClass: "num-cell" }, [
                    _vm._v(_vm._s(_vm.totalMachineData.newMachineCount)),
                  ]),
                  _vm._v(_vm._s(_vm.totalMachineData.unit)),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bar-area-cell cell-border top10-cell" },
          [
            _c("h4", { staticClass: "cell-title" }, [
              _c("span", { staticClass: "title-icon" }),
              _vm._v(_vm._s(_vm.tempTopData.title)),
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "chart-top10-cell",
              attrs: { id: "tempTop10Elem" },
            }),
            _vm._v(" "),
            _vm.isNoTempData
              ? _c("div", { staticClass: "empty-wrap" }, [
                  _c("div", { staticClass: "empty-img-bg" }),
                  _vm._v("\n        暂无数据\n      "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("Spin", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isRequesting,
                  expression: "isRequesting",
                },
              ],
              attrs: { fix: "" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bar-area-cell cell-border top10-cell" },
          [
            _c("h4", { staticClass: "cell-title" }, [
              _c("span", { staticClass: "title-icon" }),
              _vm._v(_vm._s(_vm.top10Cities.title)),
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "chart-top10-cell",
              attrs: { id: "tempTop10CityElem" },
            }),
            _vm._v(" "),
            _vm.isNoCityData
              ? _c("div", { staticClass: "empty-wrap" }, [
                  _c("div", { staticClass: "empty-img-bg" }),
                  _vm._v("暂无数据"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("Spin", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isRequesting,
                  expression: "isRequesting",
                },
              ],
              attrs: { fix: "" },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "split-row" }),
      _vm._v(" "),
      _c("div", { staticClass: "map-and-line-wrap" }, [
        _c(
          "div",
          {
            staticClass: "bottom-cell map-cell",
            attrs: { id: "fullScreenWrap" },
          },
          [
            _c(
              "h4",
              {
                staticClass: "cell-title",
                style: {
                  "background-color": _vm.mapIsFullscreen ? "#e0e0e0" : "",
                },
              },
              [
                _c("span", { staticClass: "title-icon" }),
                _vm._v(_vm._s(_vm.customerMapData.title) + "\n        "),
                _c("Button", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isHasMapData,
                      expression: "isHasMapData",
                    },
                  ],
                  staticClass: "fullScreenBtn",
                  attrs: {
                    shape: "circle",
                    icon: !_vm.mapIsFullscreen ? "ios-expand" : "ios-contract",
                  },
                  on: { click: _vm.clickSwitchFullscreenHandle },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "map-wrap", attrs: { id: "mapElem" } }),
            _vm._v(" "),
            _c("Spin", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLoadingMap || _vm.isRequesting,
                  expression: "isLoadingMap || isRequesting",
                },
              ],
              attrs: { fix: "" },
            }),
            _vm._v(" "),
            !_vm.isHasMapData
              ? _c(
                  "div",
                  { staticClass: "empty-wrap", staticStyle: { top: "100px" } },
                  [
                    _c("div", { staticClass: "empty-img-bg" }),
                    _vm._v("暂无数据"),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bottom-cell line-cell" },
          [
            _c("h4", { staticClass: "cell-title" }, [
              _c("span", { staticClass: "title-icon" }),
              _vm._v(_vm._s(_vm.tempLineData.title) + "客户登录情况"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "line-wrap", attrs: { id: "lineElem" } }),
            _vm._v(" "),
            !_vm.isHasLineBarData
              ? _c("div", { staticClass: "empty-wrap" }, [
                  _c("div", { staticClass: "empty-img-bg" }),
                  _vm._v("暂无数据"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("Spin", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isRequesting,
                  expression: "isRequesting",
                },
              ],
              attrs: { fix: "" },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }