<template>
  <div class="common-wrap-elem" style="width: 100%;height: 100%;">
    <!-- @load="loaded" -->
    <iframe
      frameborder="0"
      width="100%"
      height="100%"
      :src="iframeUrl"
      ></iframe>
  </div>
</template>

<script>
export default {
  components: {},
  filters: {},
  props: {},
  data() {
    return {
      iframeUrl: "https://m.kuaidi100.com/result.jsp?nu=SF12345678&com=shunfeng"
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="less" scoped>
</style>
