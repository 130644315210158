<template>
    <div class="store-contain store-details ">
        <StoreHead></StoreHead>
        <div class="store-tabs ">
            <div class="store-Breadcrumb w-store">
                <span>当前位置：</span>
                <Breadcrumb separator=">">
                    <BreadcrumbItem :to="`/STORE?active_coding=${currentData.active_coding}`">全部应用</BreadcrumbItem>
                    <BreadcrumbItem>{{currentData.product_type}}</BreadcrumbItem>
                </Breadcrumb>
            </div>            
        </div>
        <!-- 电商详情 -->
        <div class="w-store store-details-box">
            <div class="right">
                <h2>{{currentData.product_name}}</h2>
                <div class="store-detail-html" v-html="currentData.product_introduct">
                </div>
            </div>
            <div class="left">
                <div class="box store-price" v-if="currentData.if_open === 1">
                    <!-- 后台让先隐藏 -->
                  <!-- <h2>应用价格</h2>
                    <span>
                        <h2>¥{{currentData.product_price}}/年</h2>
                    </span> -->
                    <Button @click="subscribe_now()">立即咨询</Button>
                </div>
                <div class="box store-price"  v-if="currentData.if_open !== 1">
                    <h2>应用时效</h2>
                    <span>
                        <p>截止有效期</p>
                        <h3>{{currentData.validit_date}}</h3>
                    </span>
                    <a href="###" @click="apply_sale_billing()">申请发票</a>
                </div>
                <div class="box store-explain">
                    <h2>使用说明</h2>
                    <div class="box-file">
                        <a :href="currentData.use_explain" target="_blank">
                            <img src="../../assets/image/icon3.png"/>
                        </a>
                    </div>
                </div>
                <div class="box store-notes">
                    <h2>购买须知</h2>
                    <div class="box-file" v-html="currentData.buyer_notice">
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import StoreHead from './head.vue'
export default {
    name: 'CustomizedPageOne',
    components:{StoreHead},
    data() {
        return {
            currentData:{}
        }
    },
    mounted() {
        this.currentData = this.$store.state.appstore.currentData;
    },
    methods: {
        subscribe_now(){
            // 客户立即订阅
            console.log("立即订阅");
          
             R3.network.post('/roc/api/product/subscribe_now',{
                "active_coding": this.currentData.active_coding,
                "product_id":this.currentData.product_id,
                "order_amt":this.currentData.product_price,
                "order_time":new Date()
            }).then(res => {
                this.loading = false;           
                if (res.data.code === 0) {
                    // 产品大类
                    this.$Modal.fcSuccess({
                        title:'成功',
                        mask:true,
                        content:'已发送订阅通知给服务人员，稍后会有专人联系您！'
                    })
 

                }

            })
            

        },
        apply_sale_billing(){
            // 申请开票
            R3.network.post('/roc/api/order/apply_sale_billing',{
                "active_coding": this.currentData.active_coding,
                "product_id":this.currentData.product_id,
                "apply_time":''
            }).then(res => {
                this.loading = false;           
                if (res.data.code === 0) {
                    // 产品大类
                    this.$Modal.fcSuccess({
                        title:'成功',
                        mask:true,
                        content:'已发送开票通知给服务人员，稍后会有专人联系您！'
                    })
 

                }

            })
        }
        
        
    }
}
</script>
  
<style scoped lang="less">
.store-contain {
    background-color: #fff;
    .w-store {
        width: 1180px;
        margin: 0 auto;
    }
    .store-tabs {
       box-shadow: inset 0px -1px 0px 0px #D8D8D8;
    }
    .store-Breadcrumb{
       color: #626262;
       line-height: 50px;
       font-size: 14px;
       height: 50px;
       display: flex;
       .ark-breadcrumb{
           font-size: 14px;
       }
       .ark-breadcrumb > span:last-child{
        font-weight: normal;
       }
    }
    .store-details-box{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .right{
            flex: 1;
            border: 1px solid #DDDDDD;
            padding: 24px;
            h2{
                font-size: 22px;
                font-weight: 500;
                color: #333333;
                margin-bottom: 8px;
                line-height: 30px;
                letter-spacing: 1px;
            }
        }
        .left{
            width: 230px;
            margin-left: 20px;
            .box{
                border: 1px solid #DDDDDD; 
                padding: 24px;
                margin-bottom: 13px;
                button{
                    width: 192px;
                    height: 32px;
                    background: #1C52CA;
                    border-radius: 4px;
                    color: #fff;

                }
                h2{
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                    margin-bottom: 8px;
                }
                em{
                        font-style: normal;
                        color:#1C52CA;
                }
                span{
                    height: 80px;
                    background: #F6F8FF;
                    display: block;
                    width: 100%;
                    margin-bottom: 8px;
                   
                    h2{
                        line-height: 80px;
                        font-size: 24px;
                        font-weight: 500;
                        color: #333333;
                        letter-spacing: 1px;
                        text-align: center;
                    }
                    p{
                        font-size: 12px;
                        font-weight: 400;
                        color: #333333;
                        padding-top: 14px;
                        text-align: center;
                        margin-bottom: 4px;
                        line-height: 17px;

                    }
                    h3{
                        font-size: 24px;
                        font-weight: 500;
                        color: #333333;
                        letter-spacing: 1px;
                        text-align: center;

                    }
                }

            }
            .store-explain{
                margin-top: 15px;
            }
        }
        .box-file{
            img {
                width: 26px;
                height: 32px;
                margin-top: 16px;
            }
        }

    }
    .store-notes {
        .box-file {
            line-height: 1.5;          
        }
    }

}
.store-detail-html{
    line-height: 1.5;
}

</style>
<style>
.store-notes .box-file p{
    padding-bottom: 16px;
    padding-left: 20px;
    background: url('../../assets/image/tip.png') no-repeat 2px 2px;
    background-size: 12px 12px;

}

</style>
  