var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "store-head" }, [
    _c("div", { staticClass: "w-store head-img" }, [
      _c("h2", { staticClass: "store-title" }, [_vm._v(_vm._s(_vm.head))]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.title))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }