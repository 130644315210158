<template>
        <div class="store-head ">
            <div class="w-store head-img">
                <h2 class="store-title">{{ head }}</h2>
                <p>{{ title }}</p>
            </div>
        </div>
</template>
  
<script>
export default {
    name: 'head',
    data() {
        return {
            head: '零售应用商店', // 源表表名
            title: '零售应用商店，提供部署及管理功能，用户可以基于应用模板快速地一键部署应用。',            
        }
    }
}
</script>
  
<style scoped lang="less">
.store-contain {
    .w-store {
        width: 1180px;
        margin: 0 auto;
    }


    .store-head {
        background: url(../../assets/image/store.png) no-repeat top right;
        color: #FFFFFF;
        background-color: #123FA8;
        background-size: auto 100%;

        .store-title {
            line-height: 30px;
            font-size: 22px;
            font-weight: 500;
            padding-top: 80px;
            line-height: 30px;
        }

        p {
            line-height: 16px;
            font-size: 12px;
            padding: 10px 0 24px 0;
        }
    }
}
</style>
  