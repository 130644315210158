import Store from '../projectRoutes/store/Store';
import StoreDetails from '../projectRoutes/store/StoreDetails';
import OutPage from '../projectRoutes/outPage/index';

export default [
  {
    path: '/STORE',
    component: Store
  },
  {
    path: '/STOREDETAILS',
    component: StoreDetails
  },
  {
    path: '/EMS',
    component: OutPage
  },
];
