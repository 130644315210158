var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "loginBG", attrs: { "data-key": "LOGIN_PAGE" } },
    [
      _c(
        "div",
        { staticClass: "login-wrap" },
        [
          _c("img", {
            staticClass: "logo-img",
            attrs: { src: require("../assets/image/logo.1.png"), alt: "logo" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "login-content" }, [
            _c(
              "div",
              { staticClass: "tab-wrap" },
              _vm._l(_vm.tabBtns, function (tab, index) {
                return _c(
                  "span",
                  {
                    key: index,
                    staticClass: "tab-btn",
                    class: _vm.activeType == tab.type ? "tab-active" : "",
                    on: {
                      click: function ($event) {
                        return _vm.switchTypeFn(tab)
                      },
                    },
                  },
                  [_vm._v(_vm._s(tab.txt))]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.activeType == "SMS"
              ? _c("div", { staticClass: "login-from" }, [
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c("Icon", {
                        staticClass: "form-icon",
                        attrs: { type: "ios-phone-portrait" },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.phoneNum,
                            expression: "phoneNum",
                          },
                        ],
                        ref: "userPhone",
                        staticClass: "input-cell",
                        attrs: { type: "text", placeholder: "请输入手机号" },
                        domProps: { value: _vm.phoneNum },
                        on: {
                          keyup: function ($event) {
                            return _vm.nativeKeyUpHandle({ $event })
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.phoneNum = $event.target.value
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c("Icon", {
                        staticClass: "form-icon",
                        attrs: { type: "ios-mail-outline" },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.smsCode,
                            expression: "smsCode",
                          },
                        ],
                        ref: "userMsgcode",
                        staticClass: "input-cell",
                        attrs: {
                          type: "text",
                          maxlength: "6",
                          placeholder: "请输入短信验证码",
                        },
                        domProps: { value: _vm.smsCode },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.smsCode = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.isCanGetMsg
                        ? _c(
                            "span",
                            {
                              staticClass: "fixed-cell sms-btn",
                              on: { click: _vm.getSmsCode },
                            },
                            [_vm._v("获取短信验证码")]
                          )
                        : _c("span", { staticClass: "count-text fixed-cell" }, [
                            _vm._v(_vm._s(_vm.second) + " 秒后可重发"),
                          ]),
                    ],
                    1
                  ),
                ])
              : _c("div", { staticClass: "login-from" }, [
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c("Icon", {
                        staticClass: "form-icon",
                        attrs: { type: "ios-contact-outline" },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        ref: "username",
                        staticClass: "input-cell",
                        attrs: { type: "text", placeholder: "请输入用户名" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c("Icon", {
                        staticClass: "form-icon",
                        attrs: { type: "ios-lock-outline" },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        ref: "password",
                        staticClass: "input-cell",
                        attrs: { type: "password", placeholder: "请输入密码" },
                      }),
                    ],
                    1
                  ),
                ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-btn-wrap",
                on: { click: _vm.tempLoginHandle },
              },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.spinShow,
                        expression: "!spinShow",
                      },
                    ],
                  },
                  [_vm._v("登  录")]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "Spin",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.spinShow,
                  expression: "spinShow",
                },
              ],
              attrs: { fix: "" },
            },
            [
              _c("div", { staticClass: "loader" }, [
                _c(
                  "svg",
                  {
                    staticClass: "circular",
                    attrs: { viewBox: "25 25 50 50" },
                  },
                  [
                    _c("circle", {
                      staticClass: "path",
                      attrs: {
                        fill: "none",
                        "stroke-width": "5",
                        "stroke-miterlimit": "10",
                      },
                    }),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }