// 企业Logo、Banner配置信息
import enterpriseLogo from './src/assets/image/logo.png';
import enterpriseBanner from './src/assets/image/banner.png';
import Login from './src/component/Login.vue';
import WelcomePage from './src/component/WelcomePage.vue';
import customizedPageConfig from './src/config/customized.page.config';
import customizedModalConfig from './src/config/customized.modal.config';
import projectRoutes from './src/config/router.config'
import RosComanyPointOperation from './src/component/RosComanyPointOperation/index.vue';
// 自定义换肤
import './static/r3Theme.less'; // 定制r3样式
import './static/arkuiTheme.less'; // 定制ark-ui样式
import "./src/styles/index.less";
// 注册状态
import Appstore from './src/projectRoutes/store/utils/state';

R3.store.registerModule('appstore', Appstore);


const {
  network,
  urlSearchParams
} = R3;

Vue.prototype.$network = network;
Vue.prototype.$urlSearchParams = urlSearchParams;
Vue.component('RosComanyPointOperation',RosComanyPointOperation)
R3.launchApplication({
  customizeWaterMark: {
    closure: {
      text: '结案',
      top: '43px',
      left: '25px',
      width: '50px'
    },
  },
  ossRealtimeSave: true, // 上传自动保存图片
  // 定制Logo
  image: {
    enterpriseLogo,
    enterpriseBanner,
  },
  // 全局组件
  globalComponent: {
    Login,
    WelcomePage
  },
  // 定制路由
  projectRoutes,
  quietRoutes: ['/STORE','/STOREDETAILS',"/CUSTOMIZED", "/EMS"],
  layoutDirection: false, // 默认是false ,水平排版 ，true 是垂直排版
  externalModules: customizedPageConfig, // 自定义界面
  externalModals: customizedModalConfig, // 自定义弹框
  ignoreGateWayPattern: [/\/jflow\/*/g, /\/api\/*/g], // 框架默认禁用的网关逻辑的正则模式匹配
  Version: '1.4', // 版本号
  enableGateWay: false, // 网关是否打开,
  // messageSwitch:true // 异步任务
  customizeMixins: { 
    // 向列表界面混入事件
     standardTableListsCustomize: {
       methods: {
         // 定义渲染函数,名称必须叫columnRenderer
         // 参数cellData: 可以拿到当前列的所有数据。
         // 参数render:渲染对象,其中renderComponent字段传(局部组件)组件实例或(全局组件)组件名称。render对象里定义的其他字段可以作为组件参数，例如示例中的test字段
         columnRenderer(cellData, render) {
           const webconf = cellData.webconf;
           if (webconf && webconf.customerurl && webconf.customerurl.objdistype === 'defined') {
             render.renderContainer = 'CellRender', // 表示用组件方式渲染
             render.renderComponent = webconf.customerurl.cellcomponent;// 这里传入的是全局组件的名称
           }
         },
       },
     },
   }
});

