<template>
    <div class="store-contain">
        <StoreHead></StoreHead>
        <div class="border-bottom">
                <div class="store-button-bar w-store">
                <div class="store-button">
                    <!-- <Button v-for="(item, i) in ButtonGroup" :key="i" :class="item.value === activetab ? 'actived' : ''"
                        @click="changeButton(item)">
                        {{ item.lable }}
                    </Button> -->
                    <Button v-for="(item, i) in ButtonGroup" :key="i" :class="item === activetab ? 'actived' : ''"
                    @click="changeButton(item)">
                    {{ item }}
                    </Button>
                </div>
                <div class="store-total">共{{totall}}个应用</div>
            </div>

            </div>
        <div class="store-tabs w-store">
            
                        
            <Row type="flex" class="store-cards code-row-bg">
                <!-- 卡片展示 -->
                <Spin v-show="loading" fix></Spin>
                <Col class="store-card"  span="6" v-for="(item, i) in storeList" :key="i" >
                    <div class="store-card-box"  @click="Jumpto(item)">
                        <img :src="item.product_icon"/>
                        <h2>{{item.product_name}}</h2>
                        <p>{{item.new_product_introduct}}</p>
                        <div class="store-card-status">
                            <Button class="actived" v-show="item.if_open !== 1">已开通</Button>
                            <Button v-show="item.if_open === 1">未开通</Button>
                            <div class="store-time"  v-show="item.if_open !== 1">截止{{item.validit_date}}有效</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
</template>
  
<script>
import StoreHead from './head.vue'
import { getQueryString } from './utils/index';


export default {
    name: 'CustomizedPageOne',
    components:{StoreHead},
    data() {
        return {
            activetab: '全部',
            ButtonGroup: ['全部'
            ],
            totall:0,
            loading:false,
            storeList: [],  // 当前tab下显示的产品
            product_data:[]  //所有的产品
        }
    },
    mounted() {
        console.log('tableName', this.$route);
        this.init()
    },
    methods: {
        changeButton(value) {
            this.loading = true;           
            this.activetab = value;
            if(value!=='全部'){
                this.storeList = [];
                this.product_data.forEach(item => {
                    if(value ===item.product_type){
                        this.storeList.push(item);
                    }                        
                });
            }else{
                this.storeList = JSON.parse(JSON.stringify(this.product_data));
            }
            setTimeout(()=>{
                this.loading = false;           
            },200)
            this.totall = this.storeList.length;
        },
        Jumpto(item){
            // 跳转路由
            item.active_coding = getQueryString('active_coding').active_coding;
            this.$store.commit('setCurrentData',item);
            console.log(item)
            this.$router.push({
                path:`/STOREDETAILS`
            })


        },
        init(){ 
            this.loading = true;    
            console.log('tableName', R3.network);
            this.storeList = [];      
            R3.network.post('/roc/api/product/sale/query',{
                 'active_coding': `${getQueryString('active_coding').active_coding}`
            }).then(res => {
                this.loading = false;           
                if (res.data.code === 0) {
                    let data = res.data.data;
                    // 产品大类
                    
                    data.forEach(item => {
                        if(this.ButtonGroup.indexOf(item.product_type) === -1){
                           this.ButtonGroup.push(item.product_type);
                        }
                        // 去除展示中的
                        item.product_data.forEach((options)=>{
                            options.product_type = item.product_type;
                            if(options.product_introduct){
                                options.new_product_introduct = options.product_introduct.replace(/<\/?[^>]*>/g,'')
                            }else{
                                options.new_product_introduct = '';
                            }
                            if(options.product_icon){
                                options.product_icon = JSON.parse(options.product_icon)[0].URL
                            }

                        });
                        this.storeList.push(...item.product_data);


                    });
                    this.product_data = JSON.parse(JSON.stringify(this.storeList));
                    this.$store.commit('setProduct_data',this.product_data);
                    this.totall = this.storeList.length;

                }

            })

        }
    }
}
</script>
  
<style scoped lang="less">
.store-contain {
    .w-store {
        width: 1180px;
        margin: 0 auto;
    }


    .store-button {
        .ark-btn {
            padding: 3px 8px;
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            border-radius: 4px;
            margin: 12px 20px 12px 0;
            border: none;
        }

        .actived {
            color: #FFFEFE;
            background-color: #1C52CA;
        }
    }
    .border-bottom{
        border-bottom: 1px solid #d8d8d8;
    }
    .store-button-bar{
        display: flex;
        justify-content:space-between;
        .store-total{
            width: 100px;
            text-align: right;
            color: #626262;
            line-height: 50px;
        }
    }

    .store-cards {
        margin-left: 0px!important;
        position: relative;
        min-height: 300px;
        .store-card {
            height: 220px;
            background: #FFFFFF;
            // width: calc(25% - 20px);
            cursor: pointer;
            // margin:20px 20px 0px 0;
            margin-top: 20px;
            box-sizing: border-box;
            .store-card-box{
                padding: 30px 30px 22px 30px;
                margin-right: 20px;
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
                border-radius: 4px;
                .store-time{
                    line-height: 20px;
                }
                img{
                    width: 48px;
                    height: 48px;
                    overflow: hidden;
                    margin-bottom: 20px;
                    display: block;
                    
                    // background-color: #626262;
                }
                h2{
                    font-size: 18px;
                    font-weight: 500;
                    color: #333333;
                    line-height: 26px;
                    padding-bottom: 4px;
                }
                p{
                    height: 32px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #626262;
                    line-height: 16px;
                    margin-bottom: 20px;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;/*重点，不能用block等其他*/
                    -webkit-line-clamp: 2;/*重点IE和火狐不支持*/
                    -webkit-box-orient: vertical;/*重点*/
                }
                .store-card-status{
                    display: flex;
                    justify-content:space-between;
                    color: #999;
                    button{
                        padding: 2px 4px;
                        background: #F1F1F1;
                        border-radius: 2px;
                        color: #999;
                        border: 1px solid #B8B8B8;

                        &.actived{
                            background: #DEFFE9;
                            border-radius: 2px;
                            color: #4CBF74;
                            border: 1px solid #4CBF74;

                        }

                    }
                }

            }
         
        }
        :nth-child(4n+1){
            .store-card-box{
                 margin-right: 0px;
            }
        }
    }
}
</style>
  