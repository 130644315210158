 const Appstore= {
    state: () => ({
        product_data: window.localStorage.getItem('product_data')? JSON.parse(window.localStorage.getItem('product_data')) : [], //所有的商店
        currentData:window.localStorage.getItem('currentData')? JSON.parse(window.localStorage.getItem('currentData')) : [], //当前数据
     }),
    mutations: {
        setCurrentData(state,data){
            state.currentData = data;
            window.localStorage.setItem('currentData',JSON.stringify(data));
            
        },
        setProduct_data(state,data){
            state.product_data = data;
            window.localStorage.setItem('product_data',JSON.stringify(data));
            
        }

     },
  }
  export default Appstore