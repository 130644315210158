/**
 * 日志报表中的数据源
 */

const dateRangeInfo = {
  elemType: "DATE_RANGE",
  title: "时间范围",
  key: "time_tab",
  btnList: [
    { desc: "本月", formKey: "time_tab", valueKey: "1", isActive: true, },
    { desc: "今年", formKey: "time_tab", valueKey: "2", isActive: false, },
    { desc: "历史", formKey: "time_tab", valueKey: "3", isActive: false, },
  ]
}

const selectList = [
  // {
  //   title: "数据范围",
  //   key: "data_range",
  //   options: [{ label: "历史数据1", value: 1 }, { label: "历史数据2", value: 2 }]
  // },
  {
    title: "终端设备",
    key: "terminal_type",
    options: [
      { label: "全部设备", value: "ALL" },
      { label: "云POS", value: "云POS" },
      { label: "iShow", value: "WEIXINAPPLET"},
      { label: "手持", value: "DAMAI_SC"},
      { label: "iPad", value: "DAMAI_iPad"},
      { label: "大麦仓储", value: "DAMAI_CC"},
      { label: "台式", value: "DAMAI_TS"},
      { label: "自助", value: "DAMAI_ZZ"},
    ]
  },
  {
    title: "客户范围",
    key: "serial_no",
    options: [{ label: "全部客户", value: "ALL" }]
  },
]

// top10公用的配置源数据
const top10CustomerOption = {
  tooltip: {
    show: true, //是否开启悬浮显示
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  xAxis: {
    type: 'category',
    data: [],
    axisTick: {
      alignWithLabel: true,//X轴描述是否居中
      show: false,//X轴刻度
    },
    axisLine: {
      show: false,//X轴坐标是否显示
    },
    // 底部名称配置
    axisLabel: {
      rotate: 40,//名称旋转角度
      width: 50,
      fontSize: 11,
      // X轴标签超过4个字显示...，悬浮显示全部
      formatter: function(value, index) {
        let xLabelStr = value ? String(value) : "";
        const num = xLabelStr.length || 0;
        if (num > 4) {
          const replaceStr = xLabelStr.slice(4, num);
          const reg = new RegExp(replaceStr);
          xLabelStr = xLabelStr.replace(reg, "...");
        }
        return xLabelStr;
      }
    }
  },
  yAxis: {
    type: 'value',
    show: false,// Y轴坐标是否显示
    // max: 100,//Y轴最大值控制
  },
  series: [
    {
      // data: [100, 90, 80, 70, 60, 50, 40, 30, 20, 10],
      data: [],
      type: 'bar',
      barWidth: '10',//柱状宽度
      name: '数量',// 悬浮显示的标题
      itemStyle: {
        color: "#457CED",//条形背景色
      },
      // barCategoryGap: "70%",
    }
  ]
};
const top10CityOption = {
  tooltip: {
    show: true, //是否开启悬浮显示
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  xAxis: {
    type: 'category',
    data: [],
    axisTick: {
      alignWithLabel: true,//X轴描述是否居中
      show: false,//X轴刻度
    },
    axisLine: {
      show: false,//X轴坐标是否显示
    },
    // 底部名称配置
    axisLabel: {
      rotate: 40,//名称旋转角度
      width: 50,
      fontSize: 11,
      // X轴标签超过4个字显示...，悬浮显示全部
      formatter: function(value, index) {
        let xLabelStr = value ? String(value) : "";
        const num = xLabelStr.length || 0;
        if (num > 4) {
          const replaceStr = xLabelStr.slice(4, num);
          const reg = new RegExp(replaceStr);
          xLabelStr = xLabelStr.replace(reg, "...");
        }
        return xLabelStr;
      }
    }
  },
  yAxis: {
    type: 'value',
    show: false,// Y轴坐标是否显示
    // max: 100,//Y轴最大值控制
  },
  series: [
    {
      // data: [100, 90, 80, 70, 60, 50, 40, 30, 20, 10],
      data: [],
      type: 'bar',
      barWidth: '10',//柱状宽度
      name: '数量',// 悬浮显示的标题
      itemStyle: {
        color: "#457CED",//条形背景色
      },
      barCategoryGap: "70%",
    }
  ]
};
const top10StoreOption = {
  tooltip: {
    show: true, //是否开启悬浮显示
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  xAxis: {
    type: 'category',
    data: [],
    axisTick: {
      alignWithLabel: true,//X轴描述是否居中
      show: false,//X轴刻度
    },
    axisLine: {
      show: false,//X轴坐标是否显示
    },
    // 底部名称配置
    axisLabel: {
      rotate: 40,//名称旋转角度
      width: 50,
      fontSize: 11,
      // X轴标签超过4个字显示...，悬浮显示全部
      formatter: function(value, index) {
        let xLabelStr = value ? String(value) : "";
        const num = xLabelStr.length || 0;
        if (num > 4) {
          const replaceStr = xLabelStr.slice(4, num);
          // const reg = new RegExp(replaceStr);
          xLabelStr = xLabelStr.replace(replaceStr, "...");
        }
        return xLabelStr;
      }
    }
  },
  yAxis: {
    type: 'value',
    show: false,// Y轴坐标是否显示
    // max: 100,//Y轴最大值控制
  },
  series: [
    {
      // data: [100, 90, 80, 70, 60, 50, 40, 30, 20, 10],
      data: [],
      type: 'bar',
      barWidth: '10',//柱状宽度
      name: '数量',// 悬浮显示的标题
      itemStyle: {
        color: "#457CED",//条形背景色
      },
      barCategoryGap: "70%",
    }
  ]
};

// const barData = {
  
// }

export {
  dateRangeInfo,
  selectList,
  top10CustomerOption,
  top10CityOption,
  top10StoreOption,
}