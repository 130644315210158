



const lineOption  = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  legend: {
    data: ['客户数', '门店数', '机器数'],
    itemHeight: 10,
    itemWidth: 10,
    itemGap: 30,
    top: 450, // 强行放在底部
  },
  xAxis: [
    {
      type: 'category',
      // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      data: [],//动态变化的
      axisPointer: {
        type: 'shadow'
      },
      axisTick: {
        alignWithLabel: true,//X轴描述是否居中
        show: false,//X轴刻度
      },
      axisLine: {
        onZero: true
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: '机器数',
      min: 0,
      // max: 250,
      // interval: 50,
      axisLine: {
        show: false,//Y轴坐标是否显示
      },
      nameTextStyle: {
        padding: [0,40,0,0]
      }
    },
    {
      type: 'value',
      name: '客户数',
      min: 0,
      // max: 3500,
      // interval: 5,
      axisLine: {
        show: false,//Y轴坐标是否显示
      },
      nameTextStyle: {
        padding: [0,0,0,40]
      }
    }
  ],
  series: [
    {
      name: '门店数',
      key: "store_num",
      type: 'bar',
      barWidth: '10',//柱状宽度
      itemStyle: {
        color: "#437EF7",
      },
      data: []
    },
    {
      name: '机器数',
      key: "machine_num",
      type: 'bar',
      barWidth: '10',//柱状宽度
      itemStyle: {
        color: "#F68F38",
      },
      data: []
    },
    {
      name: '客户数',
      key: "customer_num",
      type: 'line',
      yAxisIndex: 1,
      itemStyle: {
        color: "#00AB2E",
      },
      data: []
    }
  ]
};

const noCustomerLineOption  = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  legend: {
    data: ['门店数', '机器数'],
    itemHeight: 10,
    itemWidth: 10,
    itemGap: 30,
    top: 450, // 强行放在底部
  },
  xAxis: [
    {
      type: 'category',
      // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      data: [],//动态变化的
      axisPointer: {
        type: 'shadow'
      },
      axisTick: {
        alignWithLabel: true,//X轴描述是否居中
        show: false,//X轴刻度
      },
      axisLine: {
        onZero: true
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: '机器数',
      min: 0,
      // max: 250,
      // interval: 50,
      axisLine: {
        show: false,//Y轴坐标是否显示
      },
      nameTextStyle: {
        padding: [0,40,0,0]
      }
    }
  ],
  series: [
    {
      name: '门店数',
      key: "store_num",
      type: 'bar',
      barWidth: '10',//柱状宽度
      itemStyle: {
        color: "#437EF7",
      },
      data: []
    },
    {
      name: '机器数',
      key: "machine_num",
      type: 'bar',
      barWidth: '10',//柱状宽度
      itemStyle: {
        color: "#F68F38",
      },
      data: []
    }
  ]
};

/**折线图是否影藏客户是
 * serial_no 客户范围不是ALL隐藏 折线s
 */
const filterLineOptionFn = (value = "ALL") => {
  if (value == "ALL") {
    return lineOption;
  } else {
    return noCustomerLineOption;
  }
}

export {
  lineOption,
  filterLineOptionFn
}