<template>
  <div class="welcome">
    <div class="title-wrap">
      <div class="main-title">WELCOME</div>
      <div class="sub-title">欢迎进入零售运营平台</div>
    </div>
    <div class="block-img"></div>
  </div>
</template>

<script>
export default {
  name: 'WelcomePage'
};
</script>

<style scoped lang="less">
.welcome {
  width: 100%;
  height: 100%;
  position: relative;
  .title-wrap {
    position: absolute;
    top: 274px;
    left: 94px;
    .main-title {
      font-weight: bold;
      font-size: 68px;
      margin-bottom: 20px;
    }
    .sub-title {
      font-size: 36px;
    }
  }
  .block-img {
    width: 800px;
    height: 710px;
    background-image: url('../assets/image/welcome.png');
    background-size: contain;
    position: absolute;
    right: 20px;
    top: 80px;
  }
}
</style>
