var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "common-wrap-elem",
      staticStyle: { width: "100%", height: "100%" },
    },
    [
      _c("iframe", {
        attrs: {
          frameborder: "0",
          width: "100%",
          height: "100%",
          src: _vm.iframeUrl,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }