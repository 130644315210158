var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "store-contain store-details" },
    [
      _c("StoreHead"),
      _vm._v(" "),
      _c("div", { staticClass: "store-tabs" }, [
        _c(
          "div",
          { staticClass: "store-Breadcrumb w-store" },
          [
            _c("span", [_vm._v("当前位置：")]),
            _vm._v(" "),
            _c(
              "Breadcrumb",
              { attrs: { separator: ">" } },
              [
                _c(
                  "BreadcrumbItem",
                  {
                    attrs: {
                      to: `/STORE?active_coding=${_vm.currentData.active_coding}`,
                    },
                  },
                  [_vm._v("全部应用")]
                ),
                _vm._v(" "),
                _c("BreadcrumbItem", [
                  _vm._v(_vm._s(_vm.currentData.product_type)),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-store store-details-box" }, [
        _c("div", { staticClass: "right" }, [
          _c("h2", [_vm._v(_vm._s(_vm.currentData.product_name))]),
          _vm._v(" "),
          _c("div", {
            staticClass: "store-detail-html",
            domProps: { innerHTML: _vm._s(_vm.currentData.product_introduct) },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "left" }, [
          _vm.currentData.if_open === 1
            ? _c(
                "div",
                { staticClass: "box store-price" },
                [
                  _c(
                    "Button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.subscribe_now()
                        },
                      },
                    },
                    [_vm._v("立即咨询")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentData.if_open !== 1
            ? _c("div", { staticClass: "box store-price" }, [
                _c("h2", [_vm._v("应用时效")]),
                _vm._v(" "),
                _c("span", [
                  _c("p", [_vm._v("截止有效期")]),
                  _vm._v(" "),
                  _c("h3", [_vm._v(_vm._s(_vm.currentData.validit_date))]),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "###" },
                    on: {
                      click: function ($event) {
                        return _vm.apply_sale_billing()
                      },
                    },
                  },
                  [_vm._v("申请发票")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "box store-explain" }, [
            _c("h2", [_vm._v("使用说明")]),
            _vm._v(" "),
            _c("div", { staticClass: "box-file" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.currentData.use_explain,
                    target: "_blank",
                  },
                },
                [
                  _c("img", {
                    attrs: { src: require("../../assets/image/icon3.png") },
                  }),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "box store-notes" }, [
            _c("h2", [_vm._v("购买须知")]),
            _vm._v(" "),
            _c("div", {
              staticClass: "box-file",
              domProps: { innerHTML: _vm._s(_vm.currentData.buyer_notice) },
            }),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }